import React, { useEffect } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { LayoutProvider, Wrapper } from "../../components/layout";
import { stackStyled } from "../../theme";
import { useViewPortSize } from "../../hooks";
import { StackOnBreadcrumbs, TitleAndSubtitleAssitance } from "../../atoms";
import { ResponsiveContainer } from "../../atoms/Containers";
import { LostCombination } from "../../components/sections";

const query = graphql`
  query {
    strapiLostCombinationAssistance {
      title
      subtitle
      steps
      note
    }
  }
`;

interface IKeyReplacement {
  strapiLostCombinationAssistance: {
    title: string;
    subtitle: string;
    steps: string;
    note: string;
  };
}

const BreadCrumbsContainer = stackStyled(
  "div",
  (props: { isMobile: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "10px" : "30px",
    left: props.isMobile ? "16px" : "75px",
    width: "580px",
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "100px 10px" : "50px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

const KeysReplacement: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useViewPortSize();
  const data = useStaticQuery<IKeyReplacement>(query);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('/customer-service/contact-us/');
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: "Home", href: "/" },
              { title: "Customer service", href: "/customer-service" },
              {
                title: "Lost Combination Assistance",
                href: "/customer-service/lost-combination-assistance",
              },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {/* <LostCombination
            title={data.strapiLostCombinationAssistance.title}
            subTitle={data.strapiLostCombinationAssistance.subtitle}
            steps={data.strapiLostCombinationAssistance.steps}
            note={data.strapiLostCombinationAssistance.note}
          /> */}
          <TitleAndSubtitleAssitance
            title={data.strapiLostCombinationAssistance.title}
            subtitle={'Coming Soon'}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacement;
